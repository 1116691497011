<template>
  <article class="container-fluid">
    <h1 class="main-title text-truncate">
      {{ $t("mainNav.devices") }}
    </h1>

    <device-list />
  </article>
</template>

<script>
import DeviceList from "@/components/devices/DeviceList.vue";

export default {
  name: "DevicesView",
  components: {
    DeviceList,
  },
};
</script>
