<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="5" xl="4">
          <wx-text-field
            v-model="search"
            :label="$t('common.search')"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
        </v-col>
      </v-row>
    </header>

    <wx-data-table
      :headers="computedColumns"
      :items="computedDevices"
      :items-per-page="devicesPerPage"
      :search="search"
      :filterable="true"
      :sort-by="['id']"
      :sort-desc="[false]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="computedDevices.length <= devicesPerPage"
      :footer-props="{
        itemsPerPageText: this.$t('devices.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
      :title="getRowTitle()"
      @click:row="redirectToDetailsAndActivity"
      mobile-breakpoint="sm"
      class="wx-panel"
    >
      <template #[`item.id`]="{ item }">
        <span>{{ item.id }}</span>
      </template>

      <template #[`item.dataSourceNames`]="{ item }">
        <v-chip v-for="(name, index) in item.dataSourceNames" small outlined :key="`reason-${index}`" class="mr-1">
          {{ name }}
        </v-chip>
      </template>

      <template #[`item.productionUnitNames`]="{ item }">
        <v-chip small outlined v-for="(name, index) in item.productionUnitNames" :key="`reason-${index}`" class="mr-1">
          {{ name }}
        </v-chip>
      </template>

      <template #[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t("common.listing.itemOfItems") }} {{ items.itemsLength }}
      </template>

      <template #[`item.actions`]="{ item }">
        <wx-btn-icon :aria-label="$t('common.edit')" :to="toDeviceStatus(item)" class="mr-1" small>
          <v-icon small>mdi-chevron-right</v-icon>
        </wx-btn-icon>
      </template>

      <template #no-data>
        {{ $t("devices.noDevices") }}
      </template>
    </wx-data-table>

    <devices-wifi-signal-strength />
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RouteService from "@/router/RouteService";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import DevicesWifiSignalStrength from "@/components/devices/DevicesWifiSignalStrength.vue";
import WxDataTable from "@/components/ui/WxDataTable.vue";
import WxTextField from "@/components/ui/WxTextField.vue";

export default {
  name: "DeviceList",
  components: { WxTextField, WxDataTable, DevicesWifiSignalStrength, WxBtnIcon },
  data() {
    return {
      search: "",
      devices: [],
      dataSourcesByDeviceId: new Map(),
      devicesPerPage: 100,
    };
  },
  computed: {
    ...mapGetters("devicesAdmin", ["activeFactoryDevices", "activeFactoryDataSources"]),
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    computedColumns() {
      return [
        { text: this.$t("devices.id"), class: "col-device-id", align: "start", value: "id" },
        { text: this.$t("devices.type"), class: "col-type", align: "start", value: "type" },
        { text: this.$t("devices.firmwareVersion"), class: "col-version", align: "start", value: "firmwareVersion" },
        { text: this.$t("devices.dataSources"), class: "col-ds", align: "start", value: "dataSourceNames" },
        { text: this.$t("devices.productionUnits"), class: "col-pu", value: "productionUnitNames" },
        { text: "", class: "col-actions", value: "actions", sortable: false },
      ];
    },
    computedDevices() {
      return this.devices.map((dv) => {
        let dataSourceNames = [];
        let productionUnitIds = new Set();
        const dataSources = this.dataSourcesByDeviceId.get(dv.id);
        if (dataSources && dataSources.length > 0) {
          dataSources.forEach((ds) => {
            let role = ds.role ? ds.role.toUpperCase() : "";
            dataSourceNames.push(`${ds.id} (${role})`);
            ds.production_unit_ids.forEach((puId) => productionUnitIds.add(puId));
          });
        }
        // Convert the Production Unit Ids by Production Unit names
        let productionUnitNames = [];
        productionUnitIds.forEach((puId) => {
          const pu = this.activeFactoryProductionUnits.find((pu) => pu.id === puId);
          if (pu) {
            if (!productionUnitNames.includes(pu.name)) {
              productionUnitNames.push(pu.name);
            }
          }
        });
        return {
          id: dv.id,
          type: dv.type,
          firmwareVersion: dv.firmware_version,
          dataSourceNames: dataSourceNames,
          productionUnitNames: productionUnitNames,
        };
      });
    },
  },
  watch: {
    activeFactoryDevices() {
      this.search = "";
      this.devices = this.activeFactoryDevices;
    },
    activeFactoryDataSources() {
      if (this.activeFactoryDataSources && this.activeFactoryDataSources.length > 0) {
        this.buildDataSourcesByDeviceId(this.activeFactoryDataSources);
      }
    },
    activeFactory() {
      this.fetchDevicesAndDataSources();
    }
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    ...mapActions("devicesAdmin", ["fetchActiveFactoryDevices", "fetchActiveFactoryDataSources"]),
    getRowTitle() {
      return this.$t("devices.rowTitle");
    },
    redirectToDetailsAndActivity(device) {
      this.$router.push(RouteService.toDeviceStatus(device.id));
    },
    toDeviceStatus(device) {
      return RouteService.toDeviceStatus(device.id);
    },
    buildDataSourcesByDeviceId(dataSources) {
      let map = new Map();
      dataSources.forEach((ds) => {
        let dataSourcesForDevice = map.get(ds.device_id);
        if (dataSourcesForDevice) {
          // List found. Add the data source to that list
          dataSourcesForDevice.push(ds);
        } else {
          // This is the first data source for that device
          dataSourcesForDevice = [ds];
          map.set(ds.device_id, dataSourcesForDevice);
        }
      });
      this.dataSourcesByDeviceId = map;
    },
    fetchDevicesAndDataSources() {
      if (this.activeFactory) {
        this.fetchActiveFactoryDevices(this.activeFactory.id);
        this.fetchActiveFactoryDataSources(this.activeFactory.id);
      }
    },
  },
  mounted() {
    this.fetchDevicesAndDataSources();
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .v-input {
    max-width: 400px;
  }
}
::v-deep .col-device-id {
  width: 25%;
}

::v-deep .col-type {
  width: 10%;
}

::v-deep .col-version {
  width: 18%;
}

::v-deep .col-ds {
  width: 10%;
}

::v-deep .col-pu {
  width: 37%;
}

::v-deep .col-actions {
  width: 5%;
  text-align: right !important;
}

::v-deep td:last-child:not(:first-child) {
  text-align: right !important;
}
</style>
